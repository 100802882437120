/* eslint-disable no-unused-vars */
import {
  GET_BALANCES_ERROR,
  GET_BALANCES_REQUEST,
  GET_BALANCES_FILTERS,
  GET_BALANCES_SUCCESS,
  GET_CURRENT_BALANCE_ERROR,
  GET_CURRENT_BALANCE_REQUEST,
  GET_CURRENT_BALANCE_SUCCESS,
  GET_BLOCKED_BALANCE_ERROR,
  GET_BLOCKED_BALANCE_REQUEST,
  GET_BLOCKED_BALANCE_SUCCESS,
  RESET_BALANCES
} from '../Constants/ActionTypes'

import balancesErrors from '../Errors/balancesErrors'
import moment from 'moment'
import requestApi from '../Configs/request'
import queryRequestsAPI from '../Configs/queryRequestsAPI'
import takeError from '../Errors/takeError'
import uriRequests from '../Configs/uriRequests'

moment.locale('pt-br')

export const getBalances = (
  type = 'payments',
  filters = {},
  groupByDate = null
) => {
  const request = () => {
    return { type: GET_BALANCES_REQUEST }
  }
  const requestFilters = (filters) => {
    return { type: GET_BALANCES_FILTERS, balancesFilters: filters }
  }
  const success = (balances, totalPages, total, pageSize) => {
    return { type: GET_BALANCES_SUCCESS, balances, totalPages, total, pageSize }
  }
  const failure = (error) => {
    return { type: GET_BALANCES_ERROR, error }
  }

  return async (dispatch) => {
    dispatch(request())

    const isPayments = type === 'payments'

    const urlRequest = isPayments
      ? uriRequests.getSellerPaymentsBalances
      : uriRequests.getSellerBankingBalances

    if (filters.date_range && typeof filters.date_range === 'string') {
      filters.date_range = JSON.parse(filters.date_range)
    }

    if (filters?.amount_range && typeof filters?.amount_range === 'string') {
      filters.amount_range = JSON.parse(filters.amount_range)
    }

    const params = {
      offset: 0,
      limit: 100,
      collapse: true,
      date_range: {
        gte: moment().startOf('day').toDate(),
        lte: moment().endOf('day').toDate()
      },
      ...filters,
      sort: 'date:desc,nsu:desc'
    }

    if (filters?.['blocked_amount_range[gte]']) {
      params.date_range = {
        gte: moment().add(1, 'day').startOf('day').toDate(),
        lte: moment().add(7, 'days').startOf('day').toDate()
      }
    }

    if (groupByDate) params.group_by_date = groupByDate

    if (filters?.search_id) {
      delete params.date_range
    }

    delete params.accessorName

    return queryRequestsAPI
      .get(urlRequest, { params })
      .then(({ data: { total, limit, items } }) => {
        if (items) {
          const totalPages =
            total > 0 && limit > 0 ? Math.ceil(total / limit) : 1
          if (
            filters?.search_id ||
            filters?.type ||
            filters?.net_amount_range
          ) {
            dispatch(requestFilters({ shouldHide: true }))
          } else {
            dispatch(requestFilters({ shouldHide: false }))
          }
          dispatch(success(items, totalPages, total, limit))
        }
      })
      .catch((error) => {
        const { response } = error
        if (response.status === 404) {
          return dispatch(success([], 0, 0, params.limit))
        }

        dispatch(failure(takeError(error, balancesErrors)))
      })
  }
}

export const getBlockedBalanceStatsFromGetBalances = (
  type = 'payments',
  filters = {}
) => {
  const request = () => {
    return { type: GET_BLOCKED_BALANCE_REQUEST, blockedBalanceRequesting: true }
  }
  const success = (blockedBalance) => {
    return { type: GET_BLOCKED_BALANCE_SUCCESS, blockedBalance }
  }
  const failure = (error) => {
    return { type: GET_BLOCKED_BALANCE_ERROR, blockedBalanceError: error }
  }

  return async (dispatch) => {
    dispatch(request())

    const isPayments = type === 'payments'

    const urlRequest = isPayments
      ? uriRequests.getSellerPaymentsBalances
      : uriRequests.getSellerBankingBalances

    if (filters.date_range && typeof filters.date_range === 'string') {
      filters.date_range = JSON.parse(filters.date_range)
    }

    if (filters?.amount_range && typeof filters?.amount_range === 'string') {
      filters.amount_range = JSON.parse(filters.amount_range)
    }

    const params = {
      offset: 0,
      limit: 100,
      collapse: true,
      date_range: {
        gte: moment().add(1, 'day').startOf('day').toDate(),
        lte: moment().add(7, 'days').startOf('day').toDate()
      },
      group_by_date: 1,
      'blocked_amount_range[gte]': 1,
      ...filters,
      sort: 'date:desc,nsu:desc'
    }

    delete params.accessorName

    return queryRequestsAPI
      .get(urlRequest, { params })
      .then(({ data: { total, limit, items } }) => {
        if (items) {
          if (items.length === 0) {
            dispatch(success(0))
          } else {
            if (items[0]?.blocked_balance) {
              dispatch(success(items[0].blocked_balance))
            } else {
              dispatch(success(0))
            }
          }
        }
      })
      .catch((error) => {
        dispatch(failure(takeError(error, balancesErrors)))
      })
  }
}

export const getBalanceStats = () => {
  const request = () => {
    return { type: GET_CURRENT_BALANCE_REQUEST, currentBalanceRequesting: true }
  }
  const success = (currentBalance) => {
    return { type: GET_CURRENT_BALANCE_SUCCESS, currentBalance }
  }
  const failure = (error) => {
    return { type: GET_CURRENT_BALANCE_ERROR, currentBalanceError: error }
  }

  return async (dispatch) => {
    try {
      dispatch(request())
      const urlRequest = `${uriRequests.getSellerStats}`

      const balanceResponse = await requestApi.get(urlRequest)

      dispatch(success(balanceResponse.data?.items?.current_balance))
    } catch (error) {
      dispatch(failure(takeError(error, balancesErrors)))
    }
  }
}

export const getBlockedBalanceStats = () => {
  const request = () => {
    return { type: GET_BLOCKED_BALANCE_REQUEST, blockedBalanceRequesting: true }
  }
  const success = (blockedBalance) => {
    return { type: GET_BLOCKED_BALANCE_SUCCESS, blockedBalance }
  }
  const failure = (error) => {
    return { type: GET_BLOCKED_BALANCE_ERROR, blockedBalanceError: error }
  }

  return async (dispatch) => {
    try {
      dispatch(request())
      const urlBlockedRequest = `${uriRequests.getSellerBlockedBalance}`

      const blockedBalanceResponse =
        await queryRequestsAPI.get(urlBlockedRequest)

      dispatch(success(blockedBalanceResponse?.data?.blocked_balance))
    } catch (error) {
      dispatch(failure(takeError(error, balancesErrors)))
    }
  }
}

export const resetBalances = () => {
  const reset = () => ({ type: RESET_BALANCES })

  return async (dispatch) => {
    dispatch(reset())
  }
}
