import moment from 'moment'
import takeError from 'src/Errors/takeError'
import dashBFFRequestApi from '../Configs/dashBFFRequest'
import uriRequests from '../Configs/uriRequests'
import anticipationErrors from '../Errors/anticipationErrors'

export const getAnticipationsById = async (anticipationId) => {
  try {
    const response = await dashBFFRequestApi.get(
      uriRequests.getAnticipationsById.replace('{anticipationId}', anticipationId)
    )

    return response.data
  } catch (error) {
    return takeError(error, anticipationErrors)
  }
}

export const getAnticipations = async (filters = {}) => {
  if (filters.amount_range?.gte) {
    filters.amount_range.gte = filters.amount_range.gte * 100
  }

  if (filters.amount_range?.lte) {
    filters.amount_range.lte = filters.amount_range.lte * 100
  }

  if (filters.date_range) {
    filters.created_at_range = filters.date_range

    delete filters.date_range
    delete filters.accessorName
  }

  const params = {
    offset: 0,
    limit: 100,
    created_at_range: {
      gte: moment().startOf('day').toDate(),
      lte: moment().endOf('day').toDate()
    },
    sort: 'time-descending',
    ...filters
  }

  try {
    const response = await dashBFFRequestApi.get(
      uriRequests.getAnticipations,
      { params }
    )

    return response.data
  } catch (error) {
    return takeError(error, anticipationErrors)
  }
}

export const getAnticipationsBySeller = async (sellerId, filters = {}) => {
  if (filters.amount_range?.gte) {
    filters.amount_range.gte = filters.amount_range.gte * 100
  }

  if (filters.amount_range?.lte) {
    filters.amount_range.lte = filters.amount_range.lte * 100
  }

  if (filters.date_range) {
    filters.created_at_range = filters.date_range

    delete filters.date_range
    delete filters.accessorName
  }

  const params = {
    offset: 0,
    limit: 100,
    created_at_range: {
      gte: moment().startOf('day').toDate(),
      lte: moment().endOf('day').toDate()
    },
    sort: 'time-descending',
    ...filters
  }

  try {
    const response = await dashBFFRequestApi.get(
      uriRequests.getAnticipationsBySeller.replace('{sellerId}', sellerId),
      { params }
    )

    return response.data
  } catch (error) {
    return takeError(error, anticipationErrors)
  }
}

export const getAnticipationById = async (anticipationId) => {
  try {
    const response = await dashBFFRequestApi.get(
      uriRequests.getAnticipationById.replace('{anticipationId}', anticipationId)
    )

    return response.data
  } catch (error) {
    return takeError(error, anticipationErrors)
  }
}

export const createAnticipationSimulation = async (customer, targetAmount, partnerFee, startDate, endDate) => {
  let requestData = {
    customer: customer,
    partner_fee: partnerFee,
    target_amount: targetAmount * 100,
    min_transaction_date: startDate,
    max_transaction_date: endDate,
    auto_commit: false
  }

  try {
    const response = await dashBFFRequestApi.post(
      uriRequests.createAnticipationSimulation, requestData
    )

    return response
  } catch (error) {
    return takeError(error, anticipationErrors)
  }
}

export const createAnticipations = async (prepaymentId) => {
  try {
    const url = uriRequests.createAnticipations.replace('{prepaymentId}', prepaymentId)
    const response = await dashBFFRequestApi.post(
      url
    )
    return response
  } catch (error) {
    return takeError(error, anticipationErrors)
  }
}
