import requestApi from '../Configs/request'
import uriRequests from '../Configs/uriRequests'
import { GET_BANKS_REQUEST, GET_BANKS_FAILURE, GET_BANKS_SUCCESS } from '../Constants/ActionTypes'

const sortBanksByCode = (bankA, bankB) => {
  if (bankA.code > bankB.code) {
    return 1
  }

  if (bankA.code < bankB.code) {
    return -1
  }

  return 0
}

export const getBanks = () => {
  const request = () => ({ type: GET_BANKS_REQUEST })
  const success = (banks) => ({ type: GET_BANKS_SUCCESS, banks })
  const failure = (error) => ({ type: GET_BANKS_FAILURE, error })

  return (dispatch) => {
    dispatch(request())

    const requestBanks = requestApi.get(
      uriRequests.getBanksList,
      { params: { limit: 1000 } }
    )

    requestBanks
      .then(({ data }) => {
        const { items } = data

        const banksWithIcon = [
          { code: '001', icon: 'bb.svg' },
          { code: '033', icon: 'santander.svg' },
          { code: '104', icon: 'caixa.svg' },
          { code: '237', icon: 'bradesco.svg' },
          { code: '341', icon: 'itau.svg' },
          { code: '399', icon: 'hsbc.svg' },
          { code: '422', icon: 'safra.svg' },
          { code: '477', icon: 'citibank.svg' }
        ]

        const banks = items.map(item => {
          const bankWithIcon = banksWithIcon.find(bank => bank.code === item.code)
          if (bankWithIcon) {
            return {
              ...item,
              icon: bankWithIcon.icon,
              mostUsed: true
            }
          }
          return item
        })

        dispatch(success(banks.sort(sortBanksByCode)))
      })
      .catch(error => dispatch(failure(error)))
  }
}
