import requestApi from '../Configs/request'
import uriRequests from '../Configs/uriRequests'
import {
  GET_BILLING_PAYMENT_ERROR,
  GET_BILLING_PAYMENT_REQUEST,
  GET_BILLING_PAYMENT_SUCCESS
} from '../Constants/ActionTypes'
import billingPaymentErrors from '../Errors/billingPaymentErrors'
import takeError from '../Errors/takeError'

export const getBillingPayment = (authorizationId) => {
  const request = () => ({ type: GET_BILLING_PAYMENT_REQUEST })
  const success = (billingPayment) => ({
    type: GET_BILLING_PAYMENT_SUCCESS,
    billingPayment
  })
  const failure = (error) => ({ type: GET_BILLING_PAYMENT_ERROR, error })

  return async (dispatch, getState) => {
    try {
      const {
        context: {
          seller: { primaryBankingAccountId }
        }
      } = getState()
      dispatch(request())

      const requestUri = uriRequests.getBillingPayment
        .replace('{accountId}', primaryBankingAccountId)
        .replace('{authorizationId}', authorizationId)

      const response = await requestApi.get(requestUri)
      dispatch(success(response.data))
    } catch (error) {
      dispatch(failure(takeError(error, billingPaymentErrors)))
    }
  }
}

export const getBillingPaymentReceipt = async (paymentId, getState) => {
  try {
    const state = getState()

    const {
      context: {
        seller: { primaryBankingAccountId }
      }
    } = state

    const getBillingPaymentReceiptUri = uriRequests.getBillingPaymentReceipt
      .replace('{accountId}', primaryBankingAccountId)
      .replace('{paymentId}', paymentId)

    const { data } = await requestApi.get(getBillingPaymentReceiptUri, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'binary/octet-stream'
      }
    })

    return data
  } catch (error) {
    console.error('Error occurred:', error)
    throw error
  }
}
