import createToast from '../Utils/createToast'
import requestApi from '../Configs/request'
import uriRequests from '../Configs/uriRequests'
import bankAccountErrors from '../Errors/bankAccountErrors'
import takeError from '../Errors/takeError'
import {
  CREATE_BANK_ACCOUNT_REQUEST,
  CREATE_BANK_ACCOUNT_SUCCESS,
  CREATE_BANK_ACCOUNT_ERROR,
  ASSOCIATE_BANK_ACCOUNT_REQUEST,
  ASSOCIATE_BANK_ACCOUNT_SUCCESS,
  ASSOCIATE_BANK_ACCOUNT_ERROR,
  DELETE_BANK_ACCOUNT_REQUEST,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_ERROR,
  RESET_BANK_ACCOUNTS,
  UPDATE_RECEIVING_POLICY_REQUEST,
  UPDATE_RECEIVING_POLICY_SUCCESS,
  UPDATE_RECEIVING_POLICY_ERROR,
  GET_BANK_ACCOUNTS_SUCCESS,
  GET_BANK_ACCOUNTS_REQUEST,
  GET_BANK_ACCOUNTS_ERROR,
  CREATE_SELLER_BANK_ACCOUNT_REQUEST,
  CREATE_SELLER_BANK_ACCOUNT_SUCCESS,
  CREATE_SELLER_BANK_ACCOUNT_ERROR
} from '../Constants/ActionTypes'
import dashBFFRequestApi from '../Configs/dashBFFRequest'

export const listBankAccounts = (sellerId) => {
  const request = () => ({ type: GET_BANK_ACCOUNTS_REQUEST })
  const success = bankAccounts => ({ type: GET_BANK_ACCOUNTS_SUCCESS, bankAccounts })
  const failure = error => ({ type: GET_BANK_ACCOUNTS_ERROR, error })

  return (dispatch) => {
    let url = uriRequests.listMarketplaceBankAccounts.replace('{sellerId}', sellerId)
    const requestBankAccounts = requestApi.get(url)

    dispatch(request())

    return requestBankAccounts
      .then(response => {
        dispatch(success(response.data.items))
      })
      .catch(error => dispatch(failure(takeError(error, bankAccountErrors))))
  }
}

export const createBankAccountToken = (marketplaceId, sellerId, bankAccountData, received) => {
  const request = () => ({ type: CREATE_SELLER_BANK_ACCOUNT_REQUEST })
  const success = bankAccounts => ({ type: CREATE_SELLER_BANK_ACCOUNT_SUCCESS, bankAccounts })
  const failure = error => ({ type: CREATE_SELLER_BANK_ACCOUNT_ERROR, error })

  return async (dispatch) => {
    let url = uriRequests.createBankAccountToken
    dispatch(request())

    try {
      const response = await requestApi.post(url, bankAccountData)
      await dispatch(associateBankAccountToken(response.data.id, marketplaceId, sellerId, received))
      dispatch(success(response.data))
    } catch (error) {
      dispatch(failure(takeError(error, bankAccountErrors)))
    }
  }
}

export const createBankAccount = ({
  type,
  bankCode,
  routingNumber,
  routingCheckDigit,
  accountNumber,
  holderName,
  document: accountDocument,
  setDefaultCredit
}, seller) => {
  const request = () => ({ type: CREATE_BANK_ACCOUNT_REQUEST })
  const success = (bankAccounts, bankReceiver) => ({ type: CREATE_BANK_ACCOUNT_SUCCESS, bankAccounts, bankReceiver })
  const failure = error => ({ type: CREATE_BANK_ACCOUNT_ERROR, error })

  const {
    id: sellerId
  } = seller

  return async (dispatch, getState) => {
    const {
      recipientMeans: {
        bankAccounts
        // receivingPolicy: {
        //   transfer_enabled: transferEnabled,
        //   transfer_interval: transferInterval,
        //   minimum_transfer_value: minimumTransferValue
        // }
      }
    } = getState()

    // const tokenUrl = uriRequests.createBankAccountToken
    const associateUrl = uriRequests.associateBankAccountCustomer
    // const setReceivingPolicyUrl = uriRequests.updateReceivingPolicy
    //   .replace('{sellerId}', sellerId)

    dispatch(request())

    const params = {
      holder_name: holderName,
      bank_code: bankCode.value,
      routing_number: routingNumber,
      routing_check_digit: routingCheckDigit,
      account_number: accountNumber,
      type: type.value
    }

    if (accountDocument.length === 14) {
      params.ein = accountDocument
    } else {
      params.taxpayer_id = accountDocument
    }

    try {
      // const { data: { id: tokenId } } = await requestApi.post(tokenUrl, params)

      // if (!transferEnabled && setDefaultCredit) {
      //   await requestApi.post(setReceivingPolicyUrl, {
      //     transfer_enabled: true,
      //     transfer_interval: transferInterval,
      //     minimum_transfer_value: minimumTransferValue
      //   })
      // }

      const { data: bankAccount } = await dashBFFRequestApi.post(associateUrl, {
        // 'token': tokenId,
        ...params,
        'customer': sellerId,
        'is_default_credit': false
      })

      const newBankAccountList = [...bankAccounts, bankAccount]

      dispatch(success(newBankAccountList, setDefaultCredit ? bankAccount.id : null))

      const msg = setDefaultCredit
        ? 'Sucesso! Sua conta bancária foi cadastrada e já está sendo usada para receber seus markups.'
        : 'Sucesso! Sua conta bancária já pode ser usada como meio de recebimento.'

      createToast('success', msg)
    } catch (e) {
      createToast('error', `Não conseguimos adicionar a sua <strong>Conta Bancária.</strong> Tente novamente.  ERRO <strong>${e.message}</strong>`)
      dispatch(failure(takeError(e, bankAccountErrors)))
    }
  }
}

export const associateBankAccountToken = (token, marketplaceId, sellerId, received) => {
  const request = () => ({ type: ASSOCIATE_BANK_ACCOUNT_REQUEST })
  const success = () => ({ type: ASSOCIATE_BANK_ACCOUNT_SUCCESS })
  const failure = error => ({ type: ASSOCIATE_BANK_ACCOUNT_ERROR, error })

  return async dispatch => {
    dispatch(request())
    let url = uriRequests.associateBankAccountCustomer

    let requestData = {
      'token': token,
      'customer': sellerId,
      'is_default_credit': received
    }

    try {
      await requestApi.post(url, requestData)
      dispatch(success())
    } catch (error) {
      dispatch(failure(takeError(error, bankAccountErrors)))
    }
  }
}

export const removeBankAccount = (bankAccountId) => {
  const request = () => ({ type: DELETE_BANK_ACCOUNT_REQUEST })
  const success = bankAccounts => ({ type: DELETE_BANK_ACCOUNT_SUCCESS, bankAccounts })
  const failure = error => ({ type: DELETE_BANK_ACCOUNT_ERROR, error })

  return (dispatch, getState) => {
    const { recipientMeans: { bankAccounts } } = getState()

    let url = uriRequests.removeBankAccount.replace('{bankAccountId}', bankAccountId)

    const requestDeleteBank = dashBFFRequestApi.delete(url)

    dispatch(request())

    return requestDeleteBank
      .then(({ data }) => {
        const updatedBankAccounts = bankAccounts.filter(bankAccount => bankAccount.id !== data.id)
        createToast('success', 'Sucesso! Sua conta bancária foi excluída.')
        dispatch(success(updatedBankAccounts))
      })
      .catch(error => {
        dispatch(failure(takeError(error, bankAccountErrors)))
        createToast('error', `Não conseguimos excluir a sua <strong>Conta Bancária.</strong> Tente novamente.  ERRO <strong>${error.message}</strong>`)
      })
  }
}

export const updateReceivingPolicy = (transferEnabled, sellerId, hideToast = false) => {
  const request = () => ({ type: UPDATE_RECEIVING_POLICY_REQUEST })
  const success = receivingPolicy => ({ type: UPDATE_RECEIVING_POLICY_SUCCESS, receivingPolicy })
  const failure = error => ({ type: UPDATE_RECEIVING_POLICY_ERROR, error })

  return async (dispatch, getState) => {
    const {
      recipientMeans: {
        receivingPolicy
      }
    } = getState()

    const urlRequest = uriRequests.updateReceivingPolicy
      .replace('{sellerId}', sellerId)

    dispatch(request())

    try {
      const { data } = await requestApi.post(urlRequest,
        {
          ...receivingPolicy,
          transfer_enabled: transferEnabled,
          minimum_transfer_value: 100.00,
          transfer_interval: 'daily'
        }
      )
      !hideToast && createToast('success', 'Sucesso! Fique tranquilo, agora você \n' +
        'está recebendo em sua <strong>Conta Digital.</strong>')
      dispatch(success(data))
    } catch (e) {
      const error = takeError(e, bankAccountErrors)
      !hideToast && createToast('error', error.message, error.status)
      dispatch(failure(error))
    }
  }
}

export const resetBankAccounts = () => {
  return (dispatch) => {
    dispatch({ type: RESET_BANK_ACCOUNTS })
  }
}
