import takeError from '../Errors/takeError'
import authenticationErrors from '../Errors/authenticationErrors'
import {
  CHECK_TOKEN_REQUEST,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_SUCCESS,
  RECOVER_ACCESS_REQUEST,
  RECOVER_ACCESS_SUCCESS,
  RECOVER_ACCESS_ERROR,
  RESET_AUTH_ERROR,
  USER_SUCCESS,
  INVALIDATE_CONNECTED_ACCOUNT_TOKEN,
  MARKETPLACE_SUCCESS,
  CHECK_CREDENTIALS_REQUEST,
  CHECK_CREDENTIALS_ERROR,
  CHECK_CREDENTIALS_SUCCESS
} from '../Constants/ActionTypes'
import requestApi from 'src/Configs/request'
import dashBFFRequest from 'src/Configs/dashBFFRequest'
import uriRequests from 'src/Configs/uriRequests'
import { removeAccount } from 'src/Utils/Utils'
import {
  getMarketplacesByPermission,
  getProfileFromMarketplace
} from '../Helpers/Permissions'
import { changeMarketplaceContext } from './context'
import { signOut } from './twofa'

export const verifyToken = (token) => {
  const request = () => ({ type: CHECK_TOKEN_REQUEST })
  const success = () => ({ type: CHECK_TOKEN_SUCCESS })
  const failure = (error) => ({ type: CHECK_TOKEN_ERROR, error })

  return (dispatch, getState) => {
    dispatch(request())

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }

    const requestToken = requestApi.get(uriRequests.checkToken, requestOptions)

    return requestToken
      .then(() => {
        dispatch(success())
      })
      .catch((error) => {
        const {
          authentication: { isLoggedIn }
        } = getState()
        if (isLoggedIn) {
          dispatch(logout('Token inválido'))
        }
        dispatch(failure(takeError(error, authenticationErrors)))
      })
  }
}

export const checkCredentials = (username, password) => {
  const request = () => ({ type: CHECK_CREDENTIALS_REQUEST })
  const success = () => ({ type: CHECK_CREDENTIALS_SUCCESS })
  const failure = (error) => ({ type: CHECK_CREDENTIALS_ERROR, error })

  // eslint-disable-next-line no-unused-vars
  return async (dispatch, getState) => {
    try {
      dispatch(request())
      const checkCredentialsRoute = uriRequests.checkCredentials
      await dashBFFRequest.post(checkCredentialsRoute, {
        username: username,
        password: password
      })

      return dispatch(success())
    } catch (error) {
      let errorMessage = error
      if (error?.response?.status === 403) {
        errorMessage = {
          response: {
            status: 403,
            data: {
              errorMessage: 'NoPermission'
            }
          }
        }
      }
      dispatch(failure(takeError(errorMessage, authenticationErrors)))
    }
  }
}

export const authenticate = (username, password) => {
  const request = () => ({ type: LOGIN_REQUEST })
  const success = (
    token,
    username,
    userId,
    marketplaceId,
    marketplaceColor,
    marketplaceName,
    marketplaceSlug
  ) => ({
    type: LOGIN_SUCCESS,
    token,
    username,
    userId,
    marketplaceId,
    marketplaceColor,
    marketplaceName,
    marketplaceSlug
  })
  const failure = (error) => ({ type: LOGIN_ERROR, error })

  return async (dispatch, getState) => {
    try {
      const {
        custom: { color: marketplaceColor },
        marketplace: {
          id: marketplaceId,
          name: marketplaceName,
          slug: marketplaceSlug
        }
      } = getState()

      dispatch(request())

      const signInPayload = {
        username,
        password
      }

      // Allowing configured marketplaces to receive legacy tokens (https://github.com/getzoop/zoop-heimdall-signin/pull/54)
      if (marketplaceId === '437ff0946c7b428eb77bf6c3065d4ee4') {
        signInPayload.marketplace_id = marketplaceId
      }

      const uriRequestSignin = uriRequests.signIn
      const { data } = await dashBFFRequest.post(
        uriRequestSignin,
        signInPayload,
        {
          ignoreAuthorization: true,
          ignoreSellerAndMarketplaceContext: true
        }
      )

      const {
        id: userId,
        first_name: firstName,
        last_name: lastName,
        token,
        permissions,
        require_email_confirm: requireEmailConfirm
      } = data

      // Esta chamada é necessária por conta da mudança de contexto de marketplace,
      // o signin/confirm retorna apenas a permissão do marketplace referente ao slug,
      // ao mudar de contexto de marketplace, precisamos saber todas as permissoes do usuário,
      // e não só a que ele logou originalmente.
      const { data: userPermissions } = await dashBFFRequest.get(
        uriRequests.userPermission.replace('{userId}', userId),
        {
          ignoreSellerAndMarketplaceContext: true,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      let permission = null
      if (!marketplaceId) {
        const marketplacesFromPermissions =
          getMarketplacesByPermission(permissions)
        const marketplace = marketplacesFromPermissions[0]
        permission = marketplace
      } else {
        permission = getProfileFromMarketplace(marketplaceId, permissions)
      }

      if (!permission) {
        const error = {
          response: {
            status: 401,
            data: {
              errorMessage: 'NoPermission'
            }
          }
        }

        return dispatch(failure(takeError(error, authenticationErrors)))
      }

      dispatch({
        type: USER_SUCCESS,
        id: userId,
        username,
        firstName,
        lastName,
        permissions: userPermissions.items || permission,
        requireEmailConfirm
      })
      dispatch(
        success(
          token,
          username,
          userId,
          marketplaceId,
          marketplaceColor,
          marketplaceName,
          marketplaceSlug
        )
      )
      dispatch(checkConnectedAccountsTokens(userId))

      if (!marketplaceId) {
        dispatch(changeMarketplaceContext({ id: permission.marketplace_id }))
        dispatch({ type: MARKETPLACE_SUCCESS, id: permission.marketplace_id })
      }
    } catch (error) {
      dispatch(failure(takeError(error, authenticationErrors)))
    }
  }
}

export const checkConnectedAccountsTokens = (connectedUserId) => {
  const invalidate = (userId, marketplaceId) => ({
    type: INVALIDATE_CONNECTED_ACCOUNT_TOKEN,
    userId,
    marketplaceId
  })

  return async (dispatch, getState) => {
    const {
      access: { connectedAccounts },
      marketplace: { id: currentMarketplaceId }
    } = getState()

    const accountsToInvalidate = removeAccount(
      {
        userId: connectedUserId,
        marketplaceId: currentMarketplaceId
      },
      connectedAccounts
    )

    let userId, marketplaceId

    for (let account of accountsToInvalidate) {
      const {
        token,
        userId: accountUserId,
        marketplaceId: accountMarketplaceId
      } = account
      userId = accountUserId
      marketplaceId = accountMarketplaceId
      try {
        await requestApi.get(uriRequests.checkToken, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
      } catch (e) {
        dispatch(invalidate(userId, marketplaceId))
      }
    }
  }
}

export const recoverAccess = (username) => {
  const request = () => ({ type: RECOVER_ACCESS_REQUEST })
  const success = () => ({ type: RECOVER_ACCESS_SUCCESS })
  const failure = (error) => ({ type: RECOVER_ACCESS_ERROR, error })
  return (dispatch) => {
    dispatch(request())
    const urlRequest = `${uriRequests.recoverAccess}?application=dash`
    return dashBFFRequest
      .post(urlRequest, {
        username
      })
      .then(() => dispatch(success()))
      .catch((error) =>
        dispatch(failure(takeError(error, authenticationErrors)))
      )
  }
}

export const logout = (reason) => {
  const success = (reason, userId, marketplaceId) => ({
    type: LOGOUT_SUCCESS,
    reason,
    userId,
    marketplaceId
  })

  return async (dispatch, getState) => {
    const {
      user: { id: userId },
      marketplace: { id: marketplaceId }
    } = getState()

    await signOut()
    return dispatch(success(reason, userId, marketplaceId))
  }
}

export const supportAuthenticate = (userId, token) => {
  const request = () => ({ type: LOGIN_REQUEST })

  const success = (id, username, token, permissions) => ({
    type: LOGIN_SUCCESS,
    id,
    token,
    permissions,
    accessOrigin: 'backoffice'
  })

  const userSuccess = (id, username, permissions) => ({
    type: USER_SUCCESS,
    id,
    username,
    permissions
  })

  const failure = (error) => ({ type: LOGIN_ERROR, error })

  const requestUser = requestApi.get(
    uriRequests.userDetail.replace('{userid}', userId),
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return (dispatch) => {
    dispatch(request())
    return requestUser
      .then(({ data: { username } }) => {
        const requestPermission = requestApi.get(
          uriRequests.userPermission.replace('{userId}', userId),
          {
            ignoreSellerAndMarketplaceContext: true,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        return requestPermission
          .then(({ data: { items: permissions } }) => {
            dispatch(userSuccess(userId, username, permissions))
            dispatch(success(userId, username, token, permissions))

            const marketplacesFromPermissions =
              getMarketplacesByPermission(permissions)
            const marketplaceId = marketplacesFromPermissions[0]?.marketplace_id

            dispatch(changeMarketplaceContext({ id: marketplaceId }))
            dispatch({ type: MARKETPLACE_SUCCESS, id: marketplaceId })
          })
          .catch((error) => {
            if (error.response) {
              dispatch(failure(takeError(error, authenticationErrors)))
            } else {
              dispatch(failure({ response: { status: 401 } }))
            }
          })
      })
      .catch((error) => {
        dispatch(failure(takeError(error, authenticationErrors)))
      })
  }
}

export const resetAuthError = () => (dispatch) =>
  dispatch({ type: RESET_AUTH_ERROR })
